<template>
  <div class="text-dark">
    <ModalHeader
      :left-text="'Kembali'"
      :middle-text="'Detail Note Penjualan'"
      :left-function="prevPage"
    />
    <div class="mt-5 pt-5">
      <b-row align-h="center" v-if="isLoading">
        <b-col cols="5" class="text-center">
          <b-spinner label="Loading..." variant="primary" large />
        </b-col>
      </b-row>

      <b-row align-h="center" v-else>
        <b-col cols="5">
          <b-row class="mb-2">
            <b-col>
              <div class="fw-bold-800 size20 mb-25">Nama Note:</div>
              <div>{{ detailOrderNote.name }}</div>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <div class="fw-bold-800 size20 mb-25">Note Penjualan:</div>
              <div>{{ detailOrderNote.note }}</div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BCollapse,
  VBToggle,
  BSpinner, // Importing BSpinner for loading indication
} from "bootstrap-vue";

import ModalHeader from "@/components/ModalHeader.vue";

export default {
  data() {
    return {
      detailOrderNote: {},
      isLoading: true, // Flag to track loading state
    };
  },
  components: {
    ModalHeader,
    BModal,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BCard,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BCollapse,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const uuid = this.$route.params.id;
      try {
        const result = await this.$store.dispatch(
          `orderNote/getDetailOrderNote`,
          { uuid }
        );
        this.detailOrderNote = result.data.data;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    prevPage() {
      this.$router.push({ name: "order-note.index" });
    },
  },
};
</script>
